<template>
  <router-view />
</template>

<style>
html,
body {
  height: 100%;
}
#app {
  height: 100%;
}
</style>
